import React from 'react'
import './hr.css'
import { fadeInLeftBig, flipInX } from 'react-animations';
import styled, { keyframes } from 'styled-components';

const fadeInAnimation = keyframes`${fadeInLeftBig}`;
const flipInAnimation = keyframes`${flipInX}`;
const FadeInDiv = styled.div`
  animation: 3s ${fadeInAnimation};
`;
const FlipInDiv = styled.div`
  animation: 2s ${flipInAnimation};
`;

const Hr = (props) => (
  <div className="header_container">
    <FlipInDiv><h1 className="header">{props.nazwa}</h1></FlipInDiv>
    <FadeInDiv>
      <hr className="divider-header"/>
    </FadeInDiv>
  </div>
);

export default Hr;
