import React from 'react'
import { Container } from 'react-bootstrap'
import './styles.css'
import { StaticImage } from "gatsby-plugin-image"

const path = "../../../../static/oferta/"

const Internist = () => (
  <Container className="text_container">
  <StaticImage variant="top" src={path + "internist.jpg"} alt="Internistyka" height={400}/>
    <div className="divider"></div>
    <p>
    Jest to podstawowa opieka lekarska naszego pacjenta. W trakcie takiej wizyty uwaga kierowana jest na wywiad oraz badanie ogólne pacjenta Jeśli sytuacja tego wymaga zlecane są badania dodatkowe
   (np. badanie krwi, moczu, kału, badanie ultrasonograficzne, RTG). Rozpoznajemy i leczymy choroby wewnętrzne i zakaźne zwierząt, prowadzimy leczenie pacjentów z chorobami przewlekłymi, kompleksowo opiekujemy się pacjentami geriatrycznymi. Dokładamy wszelkich starań by wizyty w gabinecie były przeprowadzane w przyjaznej atmosferze z jak najmniejszym stresem dla Państwa pupili.
    </p>
  </Container>
);

export default Internist;
